body {
    font-family: sans-serif;
}

button {
    background: white;
    border-radius: 5px;
    padding: 10px 20px;
    border: 0;
    margin: 10px;
    font-size: 16px;
    cursor: pointer;
    &:hover {
        background: #ddd;
    }
}

h1 {
    font-family: 'Julius Sans One', sans-serif;
    color: #aa55ff;
    text-align: center;
}

.splash {
    background: url(/static/splash.jpg) no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    h1 {
        font-size: 50px;
        margin-top: 50px;
    }
    button {
        margin-left: 50%;
        transform: translate(-50%, 0);
    }
}

.failure {
    background: #937b55 url(/static/failure.png) no-repeat 50% 50%;
    background-size: contain;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    h1 {
        font-size: 50px;
        margin-top: 50px;
    }
    button {
        margin-left: 50%;
        margin-top: 200px;
        transform: translate(-50%, 0);
    }
}

.victory {
    background: #ffdd65 url(/static/victory.gif) no-repeat 50% 50%;
    background-size: contain;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    h1 {
        font-size: 50px;
        margin-top: 50px;
    }
    h2 {
        font-size: 50px;
        margin-top: 30px;
        text-align: center;
    }
    button {
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.game {
    display: grid;
    background: url(/static/game.jpg) no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    grid-template-areas: 'title title' 'wealth narrative' 'inventory narrative';
    grid-template-rows: 50px 1fr 1fr;
    grid-template-columns: 1fr 2fr;

    .title {
        grid-area: title;
        h1 {
            font-size: 30px;
            margin: 10px 0;
        }
    }

    .wealth {
        grid-area: wealth;
        margin: 10px;
        background: rgba(255, 255, 255, 0.5) url(/static/wealth.png) no-repeat bottom right;
        background-size: 50%;
        border-radius: 10px;
        border: 1px solid #eee;
        color: black;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto 1fr;
        position: relative;
        > * {
            margin-bottom: 10px;
        }

        .turn {
            position: absolute;
            top: 0;
            right: 10px;
        }
    }
    .inventory {
        grid-area: inventory;
        margin: 10px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        border: 1px solid #eee;
        color: black;
        padding: 10px;
    }
    .narrative {
        grid-area: narrative;
        margin: 10px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        color: white;
        padding: 10px;
        font-size: 20px;
    }
}

@media (max-width: 450px) {
    .game {
        position: fixed;
        overflow: auto;
        grid-template-areas: 'title' 'narrative' 'wealth' 'inventory';
        grid-template-rows: auto;
        grid-template-columns: auto;
    }
}

.narrative-image {
    width: 100%;
    min-height: 300px;
    height: 50%;
    background-size: cover;
    background-position: 50% 50%;
}

.narrative-image-0 {
    background-image: url(/static/narratives/00.jpg);
}
.narrative-image-1 {
    background-image: url(/static/narratives/01.jpg);
}
.narrative-image-2 {
    background-image: url(/static/narratives/02.jpg);
}
.narrative-image-3 {
    background-image: url(/static/narratives/03.png);
}
.narrative-image-4 {
    background-image: url(/static/narratives/04.png);
}
.narrative-image-5 {
    background-image: url(/static/narratives/05.png);
}
.narrative-image-6 {
    background-image: url(/static/narratives/06.png);
}
.narrative-image-7 {
    background-image: url(/static/narratives/07.png);
}
.narrative-image-8 {
    background-image: url(/static/narratives/08.jpg);
}
.narrative-image-9 {
    background-image: url(/static/narratives/09.jpg);
}
.narrative-image-10 {
    background-image: url(/static/narratives/10.png);
}
.narrative-image-11 {
    background-image: url(/static/narratives/11.png);
}
.narrative-image-12 {
    background-image: url(/static/narratives/12.png);
}
.narrative-image-13 {
    background-image: url(/static/narratives/13.jpg);
}
.narrative-image-14 {
    background-image: url(/static/narratives/14.gif);
}
.narrative-image-15 {
    background-image: url(/static/narratives/15.jpg);
}
.narrative-image-16 {
    background-image: url(/static/narratives/16.jpg);
}
.narrative-image-17 {
    background-image: url(/static/narratives/17.gif);
}
.narrative-image-18 {
    background-image: url(/static/narratives/18.gif);
}
.narrative-image-19 {
    background-image: url(/static/narratives/19.gif);
}
.narrative-image-20 {
    background-image: url(/static/narratives/20.jpg);
}
.narrative-image-21 {
    background-image: url(/static/narratives/21.jpg);
}
.narrative-image-22 {
    background-image: url(/static/narratives/22.jpeg);
}
.narrative-image-23 {
    background-image: url(/static/narratives/23.jpg);
}
.narrative-image-24 {
    background-image: url(/static/narratives/24.jpg);
}
.narrative-image-25 {
    background-image: url(/static/narratives/25.jpeg);
}
.narrative-image-26 {
    background-image: url(/static/narratives/26.jpg);
}
.narrative-image-27 {
    background-image: url(/static/narratives/27.gif);
}
.narrative-image-28 {
    background-image: url(/static/narratives/28.png);
}
.narrative-image-29 {
    background-image: url(/static/narratives/29.jpg);
}
.narrative-image-30 {
    background-image: url(/static/narratives/30.gif);
}
.narrative-image-31 {
    background-image: url(/static/narratives/31.jpeg);
}
.narrative-image-32 {
    background-image: url(/static/narratives/32.jpg);
}
